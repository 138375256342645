import React from 'react';
import './App.css';

const logo: string =
  'https://firebasestorage.googleapis.com/v0/b/birria-el-compa.appspot.com/o/banner.jpg?alt=media&token=2d835373-0eb8-46cf-b9f6-928bcc904a2a';

function App() {
  return (
    <div className="App">
      <header className="App-header">
        <div className="logo-container">
          <img src={logo} className="logo" alt="logo" />
        </div>
      </header>
    </div>
  );
}

export default App;
